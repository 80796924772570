html {
  position: relative;
  min-height: 100%;
}

/* Set width on the form input elements since they're 100% wide by default */
input,
select,
textarea {
  max-width: 280px;
}

hr {
  border-color: #ddd;
}

[hidden] {
  display: none !important;
}

.body {
  /* Margin bottom by footer height */
  margin-bottom: 90px !important;
  padding-top: 50px;
  padding-bottom: 20px;
}

/* Custom navbar with buttons (copied from bootstrap.css - navbar a */
.nav > li > button:hover,
.nav > li > button:focus {
  text-decoration: none;
}

@media (max-width: 767px) {
  .nav > li > button {
    padding: 10px 15px;
  }
}

/* End */

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 90px;
}

  .footer .contacts a:hover,
  .footer .contacts a:focus {
    text-decoration: none;
  }

.btn-link:hover,
.btn-link:focus {
  text-decoration: none;
}
/* #region - Social Logins - */

.btn-social > i {
  float: left;
  padding: 3px 0 0;
}

.btn-facebook {
  color: #fff !important;
  background-color: #3b5998 !important;
}

  .btn-facebook:focus, .btn-facebook.focus, .btn-facebook:hover, .btn-facebook:active, .btn-facebook.active {
    color: #fff !important;
    background-color: #2d4373 !important;
  }

.btn-microsoft {
  color: #fff !important;
  background-color: #2672ec !important;
}

  .btn-microsoft:focus, .btn-microsoft.focus, .btn-microsoft:hover, .btn-microsoft:active, .btn-microsoft.active {
    color: #fff !important;
    background-color: #125acd !important;
  }

.btn-google {
  color: #fff !important;
  background-color: #dd4b39 !important;
}

  .btn-google:focus, .btn-google.focus, .btn-google:hover, .btn-google:active, .btn-google.active {
    color: #fff !important;
    background-color: #c23321 !important;
  }

/* #endregion */

/* Set padding to keep content from hitting the edges */
.body-content {
  padding: 20px 15px;
}

.bg-info {
  padding: 15px;
}

ul.bg-info {
  padding-left: 25px;
}

.btn-xs {
  font-size: 10px;
  height: 18px;
  width: 18px;
}

/* #region - Callout Begin - */

.bs-callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
}

  .bs-callout h4 {
    margin-top: 0;
    margin-bottom: 5px;
  }

  .bs-callout p:last-child {
    margin-bottom: 0;
  }

  .bs-callout code {
    border-radius: 3px;
  }

  .bs-callout + .bs-callout {
    margin-top: -5px;
  }

.bs-callout-danger {
  border-left-color: #d9534f;
}

  .bs-callout-danger h4 {
    color: #d9534f;
  }

.bs-callout-warning {
  border-left-color: #f0ad4e;
}

  .bs-callout-warning h4 {
    color: #f0ad4e;
  }

.bs-callout-info {
  border-left-color: #5bc0de;
}

  .bs-callout-info h4 {
    color: #5bc0de;
  }

/* #endregion */

/* styles for validation helpers */
.field-validation-error {
  color: #b94a48;
}

.field-validation-valid {
  display: none;
}

input.input-validation-error {
  border: 1px solid #b94a48;
}

input[type="checkbox"].input-validation-error {
  border: 0 none;
}

.validation-summary-errors {
  color: #b94a48;
}

.validation-summary-valid {
  display: none;
}

/* Project level custom helpers / coni2k 24 Jan. '14 */

/* #region - Spaces - */

.g-no-mb {
  margin-bottom: 0;
}


.g-no-padding {
  padding: 0;
}

.g-no-left-padding {
  padding-left: 0;
}

.g-no-side-padding {
  padding-left: 0;
  padding-right: 0;
}

/* Padding Spaces */
.g-padding-5 {
  padding: 5px;
}

.g-padding-10 {
  padding: 10px;
}

.g-padding-15 {
  padding: 15px;
}

.g-padding-20 {
  padding: 20px;
}

.g-padding-25 {
  padding: 25px;
}

.g-padding-30 {
  padding: 30px;
}

.g-padding-35 {
  padding: 35px;
}

.g-padding-40 {
  padding: 40px;
}

.g-padding-45 {
  padding: 45px;
}

.g-padding-50 {
  padding: 50px;
}

.g-pt-10 {
  padding-top: 10px;
}

.g-pt-20 {
  padding-top: 20px;
}

.g-pt-30 {
  padding-top: 30px;
}

.g-pt-35 {
  padding-top: 35px;
}

.g-pt-40 {
  padding-top: 40px;
}

.g-pt-50 {
  padding-top: 50px;
}

.g-pt-60 {
  padding-top: 60px;
}

.g-pt-70 {
  padding-top: 70px;
}

.g-pt-80 {
  padding-top: 80px;
}

.g-pt-85 {
  padding-top: 80px;
}

.g-pt-90 {
  padding-top: 90px;
}

.g-pt-100 {
  padding-top: 100px;
}

.g-pt-110 {
  padding-top: 110px;
}

.g-pt-120 {
  padding-top: 120px;
}

.g-pt-130 {
  padding-top: 130px;
}

.g-pt-140 {
  padding-top: 140px;
}

.g-pt-150 {
  padding-top: 150px;
}

.g-pt-160 {
  padding-top: 160px;
}

.g-pt-170 {
  padding-top: 170px;
}

.g-pb-0 {
  padding-bottom: 0;
}

.g-pb-10 {
  padding-bottom: 10px;
}

.g-pb-20 {
  padding-bottom: 20px;
}

.g-pb-30 {
  padding-bottom: 30px;
}

.g-pb-40 {
  padding-bottom: 40px;
}

.g-pb-50 {
  padding-bottom: 50px;
}

.g-pb-60 {
  padding-bottom: 60px;
}

.g-pb-70 {
  padding-bottom: 70px;
}

.g-pb-75 {
  padding-bottom: 75px;
}

.g-pb-80 {
  padding-bottom: 80px;
}

.g-pb-90 {
  padding-bottom: 90px;
}

.g-pb-100 {
  padding-bottom: 100px;
}

.g-pl-10 {
  padding-left: 10px;
}

.g-pl-20 {
  padding-left: 20px;
}

.g-pl-30 {
  padding-left: 30px;
}

.g-pl-40 {
  padding-left: 40px;
}

.g-pl-50 {
  padding-left: 50px;
}

.g-pl-60 {
  padding-left: 60px;
}

.g-pl-70 {
  padding-left: 70px;
}

.g-pl-80 {
  padding-left: 80px;
}

.g-pl-90 {
  padding-left: 90px;
}

.g-pl-100 {
  padding-left: 100px;
}

.g-pr-10 {
  padding-right: 10px;
}

.g-pr-20 {
  padding-right: 20px;
}

.g-pr-30 {
  padding-right: 30px;
}

.g-pr-40 {
  padding-right: 40px;
}

.g-pr-50 {
  padding-right: 50px;
}

.g-pr-60 {
  padding-right: 60px;
}

.g-pr-70 {
  padding-right: 70px;
}

.g-pr-80 {
  padding-right: 80px;
}

.g-pr-90 {
  padding-right: 90px;
}

.g-pr-100 {
  padding-right: 100px;
}

/* Margin Spaces */
.g-mt-10 {
  margin-top: 5px;
}

.g-mt-10 {
  margin-top: 10px;
}

.g-mt-20 {
  margin-top: 20px;
}

.g-mt-30 {
  margin-top: 30px;
}

.g-mt-40 {
  margin-top: 40px;
}

.g-mt-50 {
  margin-top: 50px;
}

.g-mt-60 {
  margin-top: 60px;
}

.g-mt-70 {
  margin-top: 70px;
}

.g-mt-80 {
  margin-top: 80px;
}

.g-mt-90 {
  margin-top: 90px;
}

.g-mt-100 {
  margin-top: 100px;
}

.g-mb-10 {
  margin-bottom: 5px;
}

.g-mb-10 {
  margin-bottom: 10px;
}

.g-mb-15 {
  margin-bottom: 15px;
}

.g-mb-20 {
  margin-bottom: 20px;
}

.g-mb-25 {
  margin-bottom: 25px;
}

.g-mb-30 {
  margin-bottom: 30px;
}

.g-mb-35 {
  margin-bottom: 35px;
}

.g-mb-40 {
  margin-bottom: 40px;
}

.g-mb-45 {
  margin-bottom: 45px;
}

.g-mb-50 {
  margin-bottom: 50px;
}

.g-mb-55 {
  margin-bottom: 55px;
}

.g-mb-60 {
  margin-bottom: 60px;
}

.g-mb-65 {
  margin-bottom: 65px;
}

.g-mb-70 {
  margin-bottom: 70px;
}

.g-mb-75 {
  margin-bottom: 75px;
}

.g-mb-80 {
  margin-bottom: 80px;
}

.g-mb-85 {
  margin-bottom: 85px;
}

.g-mb-90 {
  margin-bottom: 90px;
}

.g-mb-95 {
  margin-bottom: 95px;
}

.g-mb-100 {
  margin-bottom: 100px;
}

.g-ml-5 {
  margin-left: 5px;
}

.g-ml-10 {
  margin-left: 10px;
}

.g-ml-20 {
  margin-left: 20px;
}

.g-ml-30 {
  margin-left: 30px;
}

.g-ml-40 {
  margin-left: 40px;
}

.g-ml-50 {
  margin-left: 50px;
}

.g-ml-60 {
  margin-left: 60px;
}

.g-ml-70 {
  margin-left: 70px;
}

.g-ml-80 {
  margin-left: 80px;
}

.g-ml-90 {
  margin-left: 90px;
}

.g-ml-100 {
  margin-left: 100px;
}

.g-mr-5 {
  margin-right: 5px;
}

.g-mr-10 {
  margin-right: 10px;
}

.g-mr-20 {
  margin-right: 20px;
}

.g-mr-30 {
  margin-right: 30px;
}

.g-mr-40 {
  margin-right: 40px;
}

.g-mr-50 {
  margin-right: 50px;
}

.g-mr-60 {
  margin-right: 60px;
}

.g-mr-70 {
  margin-right: 70px;
}

.g-mr-80 {
  margin-right: 80px;
}

.g-mr-90 {
  margin-right: 90px;
}

.g-mr-100 {
  margin-right: 100px;
}

/*#endregion */

/* #region - Misc - */

.center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

/* #endregion */

/* #region - Table Search - */

.table-search {
  border-left: none;
}

  .table-search .btn-group .btn-u {
    top: 4px;
    padding: 0 6px;
    position: relative;
  }

  .table-search .btn-u.dropdown-toggle {
    border-left: none;
  }

  .table-search thead {
    border-bottom: solid 1px #ddd;
  }

  .table-search td {
    vertical-align: middle !important;
  }

    .table-search td img {
      width: 70px;
      height: 70px;
      margin: 0 auto;
      display: block;
    }

  .table-search .user-names span,
  .table-search .user-names small {
    display: block;
    text-align: center;
  }

  .table-search .user-names span {
    color: #555;
    font-size: 16px;
    position: relative;
  }

  .table-search .user-names small {
    color: #999;
    font-size: 13px;
  }

  .table-search td h3 {
    font-size: 16px;
    margin: 0 0 2px;
  }

  .table-search td a {
    color: #555;
  }

  .table-search td p {
    margin-bottom: 0;
  }

  .table-search td span {
    display: block;
  }

  .table-search td small {
    font-style: italic;
  }

  .table-search .s-icons {
    margin-bottom: 0;
  }

    .table-search .s-icons li {
      padding: 2px;
    }

  .table-search .table-buttons {
    margin: 0;
  }

    .table-search .table-buttons li {
      padding: 0;
    }

    .table-search .table-buttons .btn-u-sm {
      padding: 3px 6px;
    }

  .table-search .td-width {
    width: 55%;
  }

/* #endregion */

/* #region table-contributors */

.table-contributors {
  border-left: none;
}

  .table-contributors thead {
    border-bottom: solid 1px #ddd;
  }

  .table-contributors tbody tr {
    height: 6em;
  }

  .table-contributors td {
    vertical-align: middle !important;
  }

    .table-contributors td span {
      display: block;
    }

  .table-contributors .nickname {
    font-style: italic;
  }

  .table-contributors ul {
    margin: 0;
  }

  .table-contributors a:active,
  .table-contributors a:hover {
    text-decoration: none;
  }

  .table-contributors td small {
    font-style: italic;
  }

/* #endregion */
